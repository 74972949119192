/* ------------------------ Sidebar Styles ------------------------ */
.sidebar {
  width: 280px;
  background-color: #f8f9fa;
  padding: 24px;
  border-right: 1px solid #e9ecef;
  height: 100vh;
  overflow-y: auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1;
}

.folder-container {
  flex-grow: 1;
  overflow-y: auto;
}

.sidebar-footer {
  margin-top: auto;
  padding-top: 80px;
  border-top: 1px solid #f8f9fa;
}

.sidebar:hover {
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
}

/* Logo Container */
.logo-container {
  text-align: center;
  margin-bottom: 32px;
}

.sidebar-logo {
  max-width: 80%;
  height: auto;
  border-radius: 0px;
  transition: transform 0.3s ease;
}

/* Folder List Styles */
.folder-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-bottom: 20px; /* Added extra space at the bottom */
}

.folder-item {
  cursor: pointer;
  margin-bottom: 16px; /* Increased from 12px to 16px */
  font-size: 16px;
  line-height: 1.4;
  transition: all 0.2s ease;
}

.folder-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  height: 50px;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.folder-item:hover > .folder-content {
  background-color: #e9ecef;
  transform: translateX(5px);
}

.folder-icon {
  margin-right: 12px;
  font-size: 20px;
  color: #4263eb;
}

.active-folder > .folder-content {
  background-color: #4263eb;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(66, 99, 235, 0.2);
}

.active-folder .folder-icon {
  color: white;
}

/* Nested list styles */
.folder-item ul {
  list-style-type: none;
  margin: 16px 0 0 0; /* Increased top margin from 8px to 16px */
  padding-left: 24px;
}

.folder-actions {
  margin-left: auto;
  position: relative;
}

/* Home Folder Styles */
.folder-item-home {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 16px;
  height: 50px;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.folder-item-home:hover {
  background-color: #e9ecef;
  transform: translateX(5px);
}

.home-icon {
  margin-right: 12px;
  font-size: 20px;
  color: #495057;
}

.home-text {
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
}

/* Kebab Menu Styles */
.kebab-menu {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.kebab-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #495057;
  padding: 8px;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}

.kebab-button:hover {
  background-color: #e9ecef;
  transform: rotate(90deg);
}

.kebab-dropdown {
  display: none;
  position: fixed; /* Changed from absolute to fixed */
  background-color: white;
  min-width: 140px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  z-index: 9999; /* Very high z-index */
  border-radius: 8px;
  overflow: hidden;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

.kebab-dropdown button {
  color: #495057;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  text-align: left;
}

.kebab-dropdown button:hover {
  background-color: #f1f3f5;
  color: #4263eb;
}

.kebab-dropdown.show {
  display: block;
}

/* Ensure consistent folder alignment */
.folder-list li {
  text-align: left;
}

/* Menu Item Styles */
.menu-item {
  background: none;
  border: none;
  color: #495057;
  padding: 12px 16px;
  text-align: left;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.menu-item:hover {
  background-color: #f1f3f5;
  color: #4263eb;
}

/* Button Styles */
.small-btn {
  background-color: #4263eb;
  color: white;
  border: none;
  padding: 10px 18px;
  cursor: pointer;
  border-radius: 8px;
  margin: 8px 0;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.small-btn:hover {
  background-color: #3b5bdb;
}

.expand-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-right: 5px;
}

.expand-button:focus {
  outline: none;
}

.expand-button:hover {
  background-color: #fdfdfd;
}