/* ------------------------ Chat Interface Styles ------------------------ */
/* Chat Interface Styles */
.chat-container {
  max-width: 900px;
  margin: 60px auto;
  font-family: 'Roboto', sans-serif;
  background-color: #fdfdfe;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.chat-title {
  font-size: 28px;
  margin-bottom: 25px;
  color: #495057;
}

.chat-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chat-history {
  max-height: 1000px;
  overflow-y: auto;
  margin-bottom: 25px;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.08);
  white-space: pre-wrap;
}

.user-message {
  background-color: #007bff;
  color: white;
  padding: 12px;
  border-radius: 12px;
  max-width: 65%;
  word-wrap: break-word;
  align-self: flex-start;
}

.assistant-message {
  background-color: #e9ecef;
  padding: 12px;
  border-radius: 12px;
  max-width: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
  margin-left: auto;
  align-self: flex-end;
}

.chat-input {
  width: 100%;
  height: 10px; /* Reduced height */
  min-height: 50px !important; /* Added min-height to ensure consistency */
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  font-size: 16px;
  resize: none; /* Prevents manual resizing */
}

.chat-send-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 0px;
  height: 45px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.chat-send-button:hover {
  background-color: #218838;
}

.chat-send-button:active {
  transform: scale(0.98);
  background-color: #1e7e34;
}

.chat-response {
  position: relative; /* Make the response area relative to contain the absolute dropdown */
  background-color: #f1f1f1; /* Light gray background */
  padding: 10px;             /* Add some padding to the response area */
  border-radius: 6px;         /* Optional: Round the corners a bit */
  color: #333;                /* Darker text for contrast */
  margin-bottom: 10px;
  min-height: 10px;          /* Ensure the chat-response has enough height */
}

.default-text {
  color: #c4c4c4;  /* Light gray color to differentiate from regular text */
  font-style: italic;  /* Optional: You can make it italic for emphasis */
  text-align: center;  /* Center the text inside the response area */
  padding: 0px;  /* Add padding to space it well */
}

.button-dropdown-container {
  display: flex;
  justify-content: space-between; /* Push button to left and dropdown to right */
  align-items: center; /* Align items vertically in the center */
  margin-top: 10px; /* Optional: Add some space above the container */
}

.chat-send-button {
  margin-right: 20px; /* Optional: Adjust spacing between button and text input */
}

/* Color selection inside the response area */
.color-selection {
  position: absolute;   /* Make the color selection menu position absolute */
  bottom: 10px;         /* 10px from the bottom of the response area */
  right: 10px;          /* 10px from the right of the response area */
  display: flex;
  align-items: center;
  background-color: transparent;  /* Transparent background */
  padding: 0px;
  border-radius: 5px;
  box-shadow: none;  /* Remove shadow or adjust for subtle effect */
}


.color-selection label {
  margin-right: 5px; /* Add some space between label and dropdown */
}