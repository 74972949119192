/* ------------------------ App Styles ------------------------ */
/* (Keep all the existing App styles from the previous CSS) */

/* ------------------------ Sidebar Styles ------------------------ */
.sidebar {
    width: 250px;
    background-color: #f8f9fa;
    padding: 20px;
    border-right: 1px solid #ddd;
    height: 100vh;
    overflow-y: auto;
  }
  
  .logo-container {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .sidebar-logo {
    max-width: 100%;
    height: auto;
  }
  
  .folder-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .folder-item {
    cursor: pointer;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 1;
  }
  
  .folder-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    height: 20px;
  }
  
  .folder-item:hover > .folder-content {
    background-color: #e2e6ea;
    border-radius: 5px;
  }
  
  .folder-icon {
    margin-right: 10px;
    font-size: 18px;
    color: #f7c325;
  }
  
  .active-folder > .folder-content {
    background-color: #e6f0ff;
    border-radius: 5px;
  }
  
  .folder-item ul {
    list-style-type: none;
    margin: 0;
    padding-left: 20px;
  }
  
  .folder-actions {
    margin-left: auto;
    position: relative;
  }
  
  .folder-item-home {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    height: 40px;
  }
  
  .home-icon {
    margin-right: 10px;
    font-size: 18px;
  }
  
  .home-text {
    font-size: 14px;
    line-height: 1;
    margin-left: 5px;
  }
  
  .kebab-menu {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .kebab-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #333;
    padding: 5px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  
  .kebab-button:hover {
    background-color: #e2e6ea;
  }
  
  .kebab-dropdown {
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    background-color: white;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 10;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .kebab-dropdown button {
    color: black;
    padding: 8px 12px;
    text-decoration: none;
    display: block;
    font-size: 12px;
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .kebab-dropdown button:hover {
    background-color: #f1f3f5;
  }
  
  .kebab-dropdown.show {
    display: block;
  }
  
  .folder-list li {
    text-align: left;
  }
  
  .menu-item {
    background: none;
    border: none;
    color: #333;
    padding: 8px 12px;
    text-align: left;
    width: 100%;
    font-size: 14px;
    cursor: pointer;
  }
  
  .menu-item:hover {
    background-color: #f1f3f5;
  }
  
  .small-btn {
    background-color: #17a2b8;
    color: white;
    border: none;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 4px;
    margin: 5px 0;
    font-size: 14px;
  }
  
  .small-btn:hover {
    background-color: #138496;
  }
  
  /* ------------------------ Chat Interface Styles ------------------------ */
/* Chat Interface Styles */
.chat-container {
  max-width: 1200px; /* Increased from 900px for a wider container */
  margin: 60px auto;
  font-family: 'Roboto', sans-serif;
  background-color: #fdfdfe;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.chat-title {
  font-size: 28px;
  margin-bottom: 25px;
  color: #495057;
}

.chat-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chat-history {
  max-height: 1000px;
  overflow-y: auto;
  margin-bottom: 25px;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.08);
  white-space: pre-wrap;
  width: 100%;
}

.user-message {
  background-color: #007bff;
  color: white;
  padding: 12px;
  border-radius: 12px;
  max-width: 65%;
  word-wrap: break-word;
  align-self: flex-start;
}

.assistant-message {
  background-color: #e9ecef;
  padding: 12px;
  border-radius: 12px;
  max-width: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
  margin-left: auto;
  align-self: flex-end;
}

.chat-interface form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.chat-interface input[type="text"] {
  width: 100%;
  min-height: 100px;
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  font-size: 16px;
  box-sizing: border-box;
}

.chat-interface button[type="submit"] {
  background-color: #17a2b8;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease, transform 0.1s ease;
  align-self: flex-start;
}

.chat-interface button[type="submit"]:hover {
  background-color: #138496;
}

.chat-interface button[type="submit"]:active {
  transform: scale(0.98);
  background-color: #17a2b8;
}

.app-container {
  display: flex;
  height: 100vh;
  background-color: #ffffff;
}

.app-main {
  display: flex;
  width: 100%;
}

.content-section {
  padding: 20px;
  width: 100%;
  max-width: 1200px; /* Match the chat-container max-width */
  margin: 0 auto;
  overflow-y: auto;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .chat-container {
    margin: 20px auto;
    padding: 20px;
  }

  .chat-interface input[type="text"],
  .chat-interface button[type="submit"] {
    width: 100%;
  }
}
/* ============== Add these styles to handle the move dropdown menu */

.move-dropdown {
  position: relative;
  display: inline-block;
  margin-left: 10px;
}

.move-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 12px;
}

.move-button:hover {
  background-color: #0056b3;
}

.move-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.move-dropdown-content a {
  color: black;
  padding: 8px 12px;
  text-decoration: none;
  display: block;
  font-size: 14px;
}

.move-dropdown-content a:hover {
  background-color: #ddd;
}

.move-dropdown:hover .move-dropdown-content {
  display: block;
}

.move-dropdown:hover .move-button {
  background-color: #0056b3;
}

/* Optional: Adjust the "Actions" column to align properly */
.content-table td {
  vertical-align: top;
}

.title-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.title-input-editing {
  background-color: lightblue;
  border: 1px solid #007bff;
  border-radius: 4px;
  padding: 4px;
  font-size: 16px;
}

.content-row a {
  margin-left: 5px;
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

.content-row a:hover {
  text-decoration: underline;
}

.move-dropdown a {
  cursor: pointer;
}


/* Pagination container to center the page numbers */
.pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  list-style-type: none;
  padding: 0;
}

/* Style each page number */
.page-number {
  margin: 0 10px; /* Adds space between page numbers */
  cursor: pointer;
  font-size: 18px;
}

/* Style for the active/current page */
.page-number.active {
  color: red;
  text-decoration: underline;
}

/* Add hover effect for page numbers */
.page-number:hover {
  color: darkred;
}


tr[draggable="true"] {
  background-color: transparent !important;
}

tr[draggable="true"]:active {
  opacity: 0.5;
}


/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #33333372; /* Dark background for the modal */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.537);
  max-width: 500px;
  width: 100%;
}

.modal-content h3 {
  margin-top: 0;
  color: white;
}

.folder-options {
  max-height: 300px;
  overflow-y: auto;
  margin: 15px 0;
}

.folder-option-button {
  background: none;
  border: none;
  padding: 5px 10px;
  margin: 2px 0;
  width: 100%;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s, padding 0.2s; /* Add transition for padding */
  color: white;
}

.folder-option-button:hover {
  background-color: #f0f0f0; /* Light background on hover */
  color: black; /* Black text on hover */
  padding-left: 20px; /* Increase left padding on hover */
}

.close-modal-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

.close-modal-button:hover {
  background-color: #d32f2f;
}


/* Folder Option Buttons */
.folder-option-button {
  background: none;
  border: none;
  text-align: left;
  padding: 8px 0;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
}

.folder-option-button:hover {
  background-color: #f0f0f0;
  border-radius: 4px;
}

/* Additional Styling for Buttons */
.folder-option-button:focus {
  outline: none;
  background-color: #e0e0e0;
}

/* Ensure the modal-content doesn't exceed the viewport on small screens */
@media (max-width: 400px) {
  .modal-content {
    width: 90%;
    color: white;
  }
}

/*   Popup chat bot ---------------------------
/* Chat button positioned in the bottom-right corner */
.chat-popup-button {
  position: fixed;
  bottom: 20px; /* Bottom-right corner of the screen */
  right: 40px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  z-index: 1000; /* Ensure it's always on top */
}

.chat-popup-button:hover {
  background-color: #0056b3;
}

/* Chat popup window positioned in the bottom-right corner */
.popup-box {
  position: fixed;
  bottom: 80px; /* Slightly above the chat button */
  right: 20px; /* Aligned with the chat button */
  width: 400px; /* Adjust width as needed */
  height: 597px;
  background-color: rgba(1, 137, 248, 0.65);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1001; /* Ensure it's above other elements */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid #d0d0d0; /* Slightly darker border than background */

}

/* Chat container with proper padding and layout */
.chat-container {
  display: flex;
  flex-direction: column;
  height: 123%;
  padding: 10px;
  box-sizing: border-box;
}

/* Scrollable chat history */
.chat-history {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
  padding-right: 10px;
}

/* User and assistant message styles */
.assistant-message {
  background-color: #007bff00;
  color: rgb(0, 0, 0);
  padding: 0px 0px;
  border-radius: 15px;
  margin-bottom: 5px;
  max-width: 100%;
  align-self: flex-start;
  margin-right: auto;
}

.user-message {
  background-color: #f1f1f1;
  color: black;
  padding: 8px 12px;
  border-radius: 15px;
  margin-bottom: 5px;
  max-width: 75%;
  align-self: flex-end;
  margin-left: auto;
}

/* Fixed input area at the bottom */
.chat-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0; /* Padding for space around the input */
  border-top: 1px solid #e3e3e3; /* Line above the input area */
  bottom: 0; /* Fixed at the bottom of the chat window */
  width: 100%;
  left: 0;
}

/* Input box styling */
.chat-input {
  width: 95%; /* Leave space for the send button */
  height: 40px;
  padding: 10px;
  border: 2px solid #e3e3e3;
  border-radius: 5px;
  resize: none;
}

/* Send button visible and aligned next to the input */
.chat-send-button {
  margin-left: 10px;
  padding: 8px 15px;
  background-color: #17a2b8; 
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.chat-send-button:hover {
  background-color: #138496;
}

/* Close button to close the chat */
.close-icon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
}


/* Style for the Add Content textarea */
textarea {
  width: 100%;
  min-height: 100px;
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  font-size: 16px;
  box-sizing: border-box;
}

/* Style for the Add Content button */
button[onClick="addContent"] {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

button[onClick="addContent"]:hover {
  background-color: #0056b3;
}

/* Container for Add Content section */
.add-content-container {
  max-width: 1200px;
  margin: 0 auto 20px;
  padding: 0 20px;
}