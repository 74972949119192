.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(to right, #6a11cb, #2575fc);
}

.login-container {
  background-color: white;
  padding: 40px 60px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 700px;
}

.login-container h1 {
  margin-bottom: 20px;
  background: linear-gradient(to right, #6a11cb, #2575fc);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 33px;
  font-weight: bold;
}

.login-container p {
  margin-bottom: 30px;
  color: #666;
}

.login-logo {
  width: 250px;
  height: 225.33px;
  margin-bottom: 20px;
}

.google-sign-in-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background: linear-gradient(to right, #2575fc, #6a11cb);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: opacity 0.3s;
}

.google-sign-in-button:hover {
  opacity: 0.9;
}

.google-sign-in-button img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.error-message {
  color: #ff0000;
  margin-top: 10px;
}
